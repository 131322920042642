import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import "../styles/materialize.css"
import Footer from "./footer";

const ListLink = props => (
  <li style={{
    display: `inline-block`,
    marginRight: `1rem`,
  }}>
    <Link style={{
      textShadow: `none`,
      backgroundImage: `none`,
      float: `right`,
      display: `inline`,
      color: `white`,
      fontFamily: `Ubuntu`
    }} to={props.to}>{props.children}</Link>
  </li>
)

export default function Layout({children}) {
  const data = useStaticQuery(
    graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `
  )
  return (
    <div
      style={{
        margin: `3rem auto`,
        maxWidth: 650,
        padding: `0 1rem`,
      }}>
      <header style={{marginBottom: `1.5rem`, alignContent: `center`}}>
        <Link to="/" style={{
          textShadow: `none`,
          backgroundImage: `none`,
        }}>
          <h3 style={{
            display: `inline`,
            color: `white`,
            fontFamily: `Ubuntu`
          }}>
            {data.site.siteMetadata.title}</h3>
        </Link>
      </header>
      <ListLink to="/about/">about</ListLink>
      {/*<ListLink to="/overview-nfts/">nfts</ListLink>*/}
      <main>
        {children}
      </main>
      <Footer/>
    </div>
  )
}