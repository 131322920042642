import React from "react"
// import "./footer.css"

const Footer = () => {
  return (
    <footer className="page-footer"
            style={{
              backgroundColor: `transparent`,
            }}>
      <div align="center" className="footer-copyright">
        <div className="container"
             style={{
               fontSize: 12,
               color: `white`,
               fontFamily: `Ubuntu`
             }}>
          © {new Date().getFullYear()},
          {` `}
          <a href="https://www.kreide.io"
             style={{
               fontSize: 12,
               color: `white`,
               textShadow: `none`,
               display: `inline`,
               bottom: true,
               fontFamily: `Ubuntu`
             }}>
            kreide.io
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
